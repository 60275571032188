// HOTWIRED + TURBO
import * as Turbo from "@hotwired/turbo";

// ALPINE
import "alpine-turbo-drive-adapter";
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import collapse from "@alpinejs/collapse";
import focus from '@alpinejs/focus'

Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(focus);

// SWIPER (projects)
// import { register } from 'swiper/element/bundle';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// register();

window.Alpine = Alpine;

// TOUCH
function isTouchDevice() {
  return (('ontouchstart' in window) ||
  (navigator.maxTouchPoints > 0) ||
  (navigator.msMaxTouchPoints > 0));
}
const myFlag = isTouchDevice();


function initSwiper(swiperEl) {
  // swiper element
  console.log('swiperEl', swiperEl);
  // swiper parameters
  const swiperParams = {
    slidesPerView: 1,
    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    }
  };

  // now we need to assign all parameters to Swiper element
  Object.assign(swiperEl, swiperParams);

  // and now initialize it
  swiperEl.initialize();
}

document.addEventListener("turbo:load", () => {
	Alpine.start();
  
  // swiper
  // const swiperEl = document.querySelector('swiper-container');
	// if (swiperEl) initSwiper(swiperEl)
  
  // detect touch
	const body = document.getElementsByTagName('body')[0];
	if (isTouchDevice()) body.classList.add('touch');
});
